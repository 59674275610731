import clsx from "clsx"
import React from "react"
import { Link } from "react-router-dom"
import { SmartDateFormat } from "../../../util/dates"
import { stripText } from "../../../util/strings"
import { PostListItemStyles } from "../constants"
import styles from "./styles.module.css"

export const MiniLinkPreview = ({ post }) => {
  let postType = post.post_type
  if (postType.indexOf("video") > -1) {
    postType = "video"
  }

  const postDisplayName =
    post.event_type === "inPersonEvent" && post.location?.displayName
      ? post.location.displayName.indexOf(",") > -1
        ? post.location.displayName.split(",")[0]
        : post.location.displayName
      : null

  return (
    <Link
      className={clsx(styles.container, "border flex items-center")}
      to={post.nada_path}
    >
      <img
        className={styles.image}
        src={
          post.external_photo_src
            ? post.external_photo_src
            : post.band_cover_photo_url_small
        }
        alt={post.title}
      />
      <div className={styles.content}>
        <p className={PostListItemStyles.externalLink}>
          {postDisplayName
            ? `Live In-Person Event @ ${postDisplayName}`
            : postType}{" "}
          -{" "}
          {SmartDateFormat({
            dateStr: post.future_active_date
              ? post.future_active_date
              : post.active_date,
            includeTime: false,
          })}
        </p>
        <h1
          className={clsx(styles.heading, "leading-tight text-black-800")}
          dangerouslySetInnerHTML={{ __html: stripText(post.title) }}
        ></h1>
      </div>
    </Link>
  )
}
